import React from 'react';
import { graphql } from 'gatsby';
import { Layout, GeneralHero } from '@components';
import { Helmet } from 'react-helmet';
import { Content, ContentGutter, IntroWrapper } from '@styles';
import { getKeyValue, compileRichText } from '@utils';
import { LIGHT } from '@utils/vars';
import { GeneralTextWrapper } from './general-content.styles';

const NewsItemTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    content, title, summary, heroImage, releaseDate, releaseDateMeta, dateLabel,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};
  const hasImg = heroImage && heroImage.fluid;

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}"
              },
              "headline": "${title}",
              "datePublished": "${releaseDateMeta}",
              "dateModified": "${releaseDateMeta}",
              "author": {
                "@type": "Person",
                "name": "Michelle Wu"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Michelle for Boston",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://images.ctfassets.net/1hf11j69ure4/1cUKrqixPyWGHs6eVKCaij/37c79fc5f9c55731e1c1e047e5009e4f/mwfm-light__1_.png"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <GeneralHero
        title={title}
        heroImage={heroImage}
        intro={summary}
        releaseDate={releaseDate}
        dateLabel={dateLabel}
      />
      <ContentGutter>
        <Content>
          {!hasImg && summary && (
            <IntroWrapper>
              {compileRichText({ textTheme: 'primary', size: 'large', locale })(summary)}
            </IntroWrapper>
          )}
          {content && (
            <GeneralTextWrapper>
              {compileRichText({ textTheme: 'primary', size: 'small', locale })(content)}
            </GeneralTextWrapper>
          )}
        </Content>
      </ContentGutter>
    </Layout>
  );
};

export default NewsItemTemplate;

export const newsPageQuery = graphql`
  query NewsItemBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulGeneralContent {
          title
          dateLabel
          releaseDateMeta: releaseDate
          releaseDate(formatString: "MMMM DD, YYYY")
          content {
            raw
            references {
              ... on ContentfulSocialEmbed {
                caption {
                  raw
                }
                type
                contentful_id
                contentfulid
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
            }
          }
          summary {
            raw
          }
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
